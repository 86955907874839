import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  {
    path: '/', title: 'Price Check', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  } ,
  {
    path: 'pages/faq', title: 'FAQ', icon: 'ft-about', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  } ,
  {
    path: '', title: 'Contact Us', icon: 'ft-about', class: 'dropdown nav-item', isExternalLink: true, submenu: []
  } ,
  {
    path: '', title: 'About Us', icon: 'ft-about', class: 'dropdown nav-item', isExternalLink: true, submenu: []
  } 
];