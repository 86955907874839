import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeData = new BehaviorSubject<any>({}); // Initial empty data

  constructor(private apiService: ApiService) {
    this.loadThemeData();
  }

  private async loadThemeData() {
    try {
      const data = await this.apiService.fetchFAQ().toPromise();
      if(data !== "null") {
      const logoUrl = await JSON.parse(data).ClientLogoPath ? JSON.parse(data).ClientLogoPath.split('&name=')[1] : null;
      const favUrl = await JSON.parse(data).FavIconPath ? JSON.parse(data).FavIconPath.split('&name=')[1] : null;
      try {
        const image = await this.apiService.fetchLogo(JSON.parse(data).Id, logoUrl);
        const fav = favUrl ? await this.apiService.fetchFav(JSON.parse(data).Id, favUrl) : "";
        // The base64 string is now available in the "image" variable
      } catch (error) {
        console.error('Error:', error);
      }
      const parsedData = JSON.parse(data);
      const settings = {
          ClientLogoText: parsedData.ClientLogoText,
          MainPageFooter: parsedData.MainPageFooter,
          HomeScreenText: parsedData.HomeScreenText,
          Disclaimer: parsedData.Disclaimer,
          AboutUsURL: parsedData.AboutUsURL,
          ContactUsURL: parsedData.ContactUsURL,
          BannerText: parsedData.BannerText,
          MainFAQDescription: parsedData.MainFAQDescription,
          ...parsedData
      };
      localStorage.setItem('settings', JSON.stringify(settings));
      this.themeData.next(data);
    } else {
      let settings
      settings = localStorage.getItem('settings');
      this.themeData.next(settings);
    }
    } catch (error) {
      console.error('Error loading theme data:', error);
    }
  }

  getThemeDataObservable() {
    return this.themeData.asObservable();
  }
}
