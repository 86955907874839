import {
  Component,
  OnInit,
  ViewChild,
  Pipe,
  PipeTransform,
  NgModule,
} from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { ApiService } from "../shared/services/api.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ThemeService } from "../shared/services/theme.service";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ModalPopupComponent } from "../modal-popup/modal-popup.component";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit {
  @ViewChild(ModalPopupComponent) modalPopup: ModalPopupComponent;
  pharmacyImages: Record<string, string>;
  myForm: FormGroup;
  private apiBaseUrl = "https://clientapi.dev.rxlogic.com";
  private apiKey = "39474b5f-f233-421d-95f3-f3f6759eff2d";
  public data: any[] = [];
  public FAQdata: any;
  public errorMessage: string;
  public res_pricing: any[] = [];
  // public ndc: string = null;
  // public DosageArr: any[] = [];
  public MedicineArr: any[] = [];
  public apiResponse: any[] = [];
  private route: ActivatedRoute;
  public logoUrl;
  public HomeText;
  public Disclaimer;
  public uniqueMedPackSizes: any[] = [];
  public uniqueMedStrengths: any[] = [];
  public uniqueDosageForms: any[] = [];
  // public availableMedPackSizes: any[] = [];
  // public availableDosageForms: any[] = [];
  zipcodeInValid: boolean = false;
  MedicineInValid: boolean = false;
  selectedMedStrength: string = "";
  selectedMedPackSize: number = 0;
  selectedMedPack: number = 0;
  selectedDosageForm: string = "";
  QutInValid: boolean = false;
  public seachparam: any;
  public error: boolean = false;
  public isLoading: boolean = false;
  public selectedDrugObj: any;
  public randomMemberID: string;
  public patientPay: any;
  selectedButtonIndex: number = 0;
  public pharmalogo: any;
  public pharmaName: any;
  public HeaderBackgroundColor: any;
  lat: number = 51.678418;
  lng: number = 7.809007;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private themeService: ThemeService
  ) {
    this.pharmacyImages = {
      COMMUNITYIMAGE: "../../assets/img/pharmacy/Community.svg",
      COSTCO: "../../assets/img/pharmacy/Costco.svg",
      CVS: "../../assets/img/pharmacy/CVS.svg",
      defaultPharamcy1: "../../assets/img/pharmacy/defaultPharamcy1.svg",
      defaultPharamcy2: "../../assets/img/pharmacy/defaultPharamcy2.svg",
      DISCOUNTDRUGMART: "../../assets/img/pharmacy/Discount_Drug_Mart.svg",
      GIANTEAGLE: "../../assets/img/pharmacy/Giant_Eagle.svg",
      PUBLIX: "../../assets/img/pharmacy/Publix.svg",
      SAMSCLUB: "../../assets/img/pharmacy/Sams_Club.svg",
      TARGET: "../../assets/img/pharmacy/Target.svg",
      WALGREENS: "../../assets/img/pharmacy/Walgreens.svg",
      WALMART: "../../assets/img/pharmacy/Walmart.svg",
      WALMARTNEIGHBORHOODMARKET:
        "../../assets/img/pharmacy/Walmart_Neighborhood_Market.svg",
      WINNDIXIE: "../../assets/img/pharmacy/Winn_Dixie.svg",
    };
  }

  public keyword = "MedDrugName"; // Assuming you're using 'name' for typeahead suggestions
  // This function will be triggered when the user inputs text in the search box
  public searchHistory: any[] = [];

  onChangeSearch(val: string) {
    this.selectedMedPack = 30;
    this.res_pricing.length = 0;
    this.selectedDrugObj = {};
    if (val) {
      this.MedicineInValid = false;
      this.isLoading = true;
      this.fetchAndProcessDrugData(val, (arr) => {
        this.data = arr;
        this.selectedDrugObj = arr[0];
        this.isLoading = false;
      });
    }
  }

  setInitialSelection(): void {
    // Find the medicine with the smallest MedPackSize
    const smallestMedicine = this.MedicineArr.reduce(
      (prev, current) =>
        prev.MedStrength < current.MedStrength ? prev : current,
      {}
    );

    // this.selectedMedStrength = `${smallestMedicine.MedStrength}`;
    if (
      smallestMedicine.MedStrength !== null &&
      smallestMedicine.Uom !== null
    ) {
      this.selectedMedStrength = `${
        smallestMedicine.MedStrength
      } ${smallestMedicine.Uom.toLowerCase()}`;
    }
    // this.selectedMedPackSize = smallestMedicine.MedPackSize;
    this.selectedMedPackSize = this.selectedMedPack;
    this.selectedDosageForm = smallestMedicine.DosageForm;
  }

  populateUniqueValues(): void {
    this.uniqueDosageForms = [
      ...new Set(this.MedicineArr.map((medicine) => medicine.DosageForm)),
    ];
    if (this.MedicineArr.some((e) => e.MedStrength !== null)) {
      this.uniqueMedStrengths = [
        ...new Set(
          this.MedicineArr.filter((e) => e.MedStrength)
            .filter((e) => e.DosageForm == this.selectedDrugObj.DosageForm)
            .map(
              (medicine) =>
                `${medicine.MedStrength} ${medicine.Uom?.toLowerCase()}`
            )
        ),
      ];
    } else {
      this.uniqueMedStrengths = [];
    }
    // this.uniqueMedPackSizes = this.MedicineArr.reduce((prev, current) => (prev.MedPackSize < current.MedPackSize) ? prev : current ).MedPackSize
    this.uniqueMedPackSizes = [
      [
        ...new Set(
          this.MedicineArr.map((medicine) => medicine.MedPackSize).sort(
            (a, b) => a - b
          )
        ),
      ][0],
    ]; //show 30
    // this.uniqueMedPackSizes = [...new Set(this.MedicineArr.map(medicine => medicine.MedPackSize))];
  }

  setModalValue(): void {
    //Form
    this.selectedDosageForm = this.selectedDrugObj.DosageForm;
    //Dosage
    this.selectedMedStrength = `${
      this.selectedDrugObj.MedStrength
    } ${this.selectedDrugObj.Uom?.toLowerCase()}`;
    //Quantity
    this.selectedDrugObj.MedPackSize = 30;
    this.selectedMedPackSize = this.selectedMedPackSize
      ? this.selectedMedPackSize
      : this.selectedDrugObj.MedPackSize;
  }

  updateform(): void {
    const filteredMedicines = this.MedicineArr.filter(
      (medicine) => medicine.DosageForm === this.selectedDosageForm
    );
    this.uniqueMedStrengths = [
      ...new Set(
        filteredMedicines
          .filter((e) => e.MedStrength)
          .map(
            (medicine) =>
              `${medicine.MedStrength} ${medicine.Uom?.toLowerCase()}`
          )
      ),
    ];
    if (this.uniqueMedStrengths.length > 0) {
      this.selectedMedStrength = this.uniqueMedStrengths[0];
    }
    // Update available options for MedPackSize and DosageForm
    this.uniqueMedPackSizes = [
      ...new Set(filteredMedicines.map((medicine) => medicine.MedPackSize)),
    ];
    // this.uniqueDosageForms = [...new Set(filteredMedicines.map(medicine => medicine.DosageForm))];
  }

  updateDosage(): void {
    const filteredMedicines = this.MedicineArr.filter(
      (medicine) =>
        medicine.MedStrength === this.selectedMedStrength.split(" mg")[0]
    );

    // Update available options for MedPackSize and DosageForm
    this.uniqueMedPackSizes = [
      ...new Set(filteredMedicines.map((medicine) => medicine.MedPackSize)),
    ];
  }

  submitModalForm(): void {
    let isValid = true;

    const Quantity = this.selectedMedPackSize;
    if (Quantity < 1) {
      isValid = false;
      this.QutInValid = true;
      (document.getElementById("Quantity") as HTMLInputElement).textContent =
        "Please Enter Quantity";
    } else {
      this.QutInValid = false;
      this.selectedMedPack = this.selectedMedPackSize;
      const filteredMedicines = this.MedicineArr.filter((medicine) => {
        return (
          (this.selectedMedStrength === "" ||
            String(medicine.MedStrength) ===
              this.selectedMedStrength.split(" ")[0]) &&
          // (this.selectedMedPackSize === 0 || medicine.MedPackSize === this.selectedMedPackSize) &&
          (this.selectedDosageForm === "" ||
            medicine.DosageForm === this.selectedDosageForm)
        );
      });
      // const array = filteredMedicines.sort((a, b) => a.AWP - b.AWP)[0] /// filter by name also?
      const drug = filteredMedicines
        .filter((e) => e.NADAC !== null)
        .sort((a, b) => a.AWP - b.AWP)
        .filter((e) => e.MedDrugName == this.selectedDrugObj.MedDrugName)[0];
      this.selectedDrugObj = drug;
      this.onFormSubmit(new Event("submit"));
      (document.querySelector(".close") as HTMLInputElement).click();
    }
  }

  fetchAndProcessDrugData(val: string, callback: (data: any[]) => void) {
    this.apiService.getDrugData(val).subscribe((response: any) => {
      const res = JSON.parse(response);
      this.apiResponse = res;
      this.MedicineArr = res.filter(
        (e) => e.MedDrugName.toLowerCase() === res[0].MedDrugName.toLowerCase()
      );

      if (!res || res.length == 0) {
        callback([]);
        return;
      }

      let valueArray = res.map((e) => e.MedDrugName);
      let uniqueValues = [...new Set(valueArray)];
      let arr = [];
      for (let x of uniqueValues) {
        if (res.filter((e) => e.MedDrugName == x).length > 0) {
          if (
            res
              .filter((e) => e.MedDrugName == x)
              .filter((e) => e.NADAC !== null)
              .filter((e) => e.MedStrength !== null).length > 0
          ) {
            arr.push(
              res
                .filter((e) => e.MedDrugName == x)
                .filter((e) => e.MedStrength !== null)
                .filter((e) => e.NADAC !== null)
                .sort((a, b) => a.AWP - b.AWP)[0]
            );
          } else {
            arr.push(
              res
                .filter((e) => e.MedDrugName == x)
                .sort((a, b) => a.AWP - b.AWP)[0]
            );
          }
        }
      }

      callback(arr);
    });
  }
  setValue(e) {
    this.hideErrorResponse();
    this.selectedMedPack = 30;
    this.selectedMedPackSize = 30;
    this.res_pricing.length = 0;
    this.selectedMedStrength = "";

    const input = document.querySelector(
      ".customautocomplete input"
    ) as HTMLInputElement;
    this.myForm.value.drugName = e.target.textContent;
    input.value = e.target.textContent;
    this.selectedDrugObj = this.searchHistory.find(
      (f) => f.MedDrugName === e.target.textContent
    );
    localStorage.setItem("searchHistory", JSON.stringify(this.searchHistory));

    if (input.value) {
      this.isLoading = true;
      this.fetchAndProcessDrugData(input.value, (arr) => {
        this.MedicineInValid = false;
        this.data = arr;
        this.isLoading = false;
        this.setInitialSelection();
        this.populateUniqueValues();
        this.setModalValue();
      });

      if (!this.validateForm()) {
        event.preventDefault();
        return;
      }

      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });

      const requestObj = this.buildRequestObject();
      this.apiService.getDrugPricing(requestObj).subscribe(
        (response: any) => {
          this.processApiResponse(response);
        },
        (error) => {
          if (error.status === 500) {
            this.error = true;
            this.show500Response();
          }
        }
      );
    }
  }

  onFormSubmit(event: Event) {
    this.hideErrorResponse();
    this.res_pricing.length = 0;

    if (!this.validateForm()) {
      event.preventDefault();
      return;
    }

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const requestObj = this.buildRequestObject();
    this.apiService.getDrugPricing(requestObj).subscribe(
      (response: any) => {
        this.processApiResponse(response);
      },
      (error) => {
        if (error.status === 500) {
          this.error = true;
          this.show500Response();
        }
      }
    );
  }
  onZipcodeInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const zipcodeValue = inputElement.value;
    if (zipcodeValue.length === 5) {
      this.zipcodeInValid = false;
    }
  }
  validateForm(): boolean {
    const formData = this.myForm.value;
    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    const zipcode = formData.zipcode;
    let isValid = true;

    if (zipcode.length < 5 || !zipCodePattern.test(zipcode)) {
      this.zipcodeInValid = true;
      (
        document.getElementById("zipInvalidState") as HTMLInputElement
      ).textContent = "Invalid Zip Code!";
      isValid = false;
    } else {
      this.zipcodeInValid = false;
    }

    if (this.MedicineArr.length < 1 || !this.selectedDrugObj?.Ndc) {
      this.MedicineInValid = true;
      isValid = false;
    } else {
      this.MedicineInValid = false;
    }

    return isValid;
  }

  buildRequestObject() {
    const formData = this.myForm.value;
    return {
      memberNumber: this.randomMemberID,
      personCode: "01",
      ndc: this.selectedDrugObj.Ndc,
      quantity: this.selectedMedPackSize ? this.selectedMedPackSize : 30,
      daysSupply: 30,
      groupNum: this.FAQdata.Group.Number,
      radius: formData.radius,
      zip: formData.zipcode,
      maxRecords: 100,
    };
  }

  processApiResponse(response: any) {
    let ApiData = JSON.parse(response).Response;
    let ApiFilteredData = ApiData.filter(
      (e) => e.Pricing !== null && e.Pricing.Status !== 3
    );

    if (ApiFilteredData.every((e) => e.Pricing === null)) {
      this.error = true;
      this.show500Response();
      return;
    }

    if (ApiFilteredData.some((e) => e.Pricing.RejectCodes !== "")) {
      ApiFilteredData = ApiFilteredData.filter(
        (e) => e.Pricing.RejectCodes === "" || e.Pricing.RejectCodes === null
      );
    }

    if (
      !ApiFilteredData.every(
        (e) => e.Pricing.RejectCodes === "" || e.Pricing.RejectCodes === null
      )
    ) {
      this.showNullPriceRes();
      return;
    }

    const uniqueFilteredData = this.filterUniquePharmacies(ApiFilteredData);
    const changedPharmacyArr = this.alterPharmacyNames(uniqueFilteredData);

    this.res_pricing = changedPharmacyArr;
    this.markLowestPrice(changedPharmacyArr);

    this.patientPay = changedPharmacyArr[0]["Pricing"].PatientPay;
    this.pharmalogo = this.getPharmacyImageURL(
      changedPharmacyArr[0]["Pharmacy"].Name
    );
    this.pharmaName = changedPharmacyArr[0]["Pharmacy"].Name;

    if (changedPharmacyArr.length > 0) {
      this.res_pricing = changedPharmacyArr;
      setTimeout(() => {
        document.getElementById("PharamcySection").scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
        this.spinner.hide();
      }, 500);
    }
    this.populateUniqueValues();
    this.setInitialSelection();
    this.setModalValue();
  }

  filterUniquePharmacies(data: any[]): any[] {
    const uniqueAffiliationCodes = {};
    return data.filter((item) => {
      const affiliationCode = item.Pharmacy.AffiliationCode;
      if (affiliationCode === "PX1") {
        return true;
      }
      if (!uniqueAffiliationCodes[affiliationCode]) {
        uniqueAffiliationCodes[affiliationCode] = true;
        return true;
      }
      return false;
    });
  }

  markLowestPrice(array: any[]): void {
    let lowestPrice = Number.POSITIVE_INFINITY;
    let lowestPriceIndex = null;
    for (let i = 0; i < array.length; i++) {
      const patientPay = array[i].Pricing.PatientPay;
      if (patientPay < lowestPrice) {
        lowestPrice = patientPay;
        lowestPriceIndex = i;
      }
    }
    if (lowestPriceIndex !== null) {
      array[lowestPriceIndex].LowestPrice = true;
    }
  }

  // setValue(e) {
  //   this.hideErrorResponse();
  //   this.selectedMedPack = 30;
  //   this.selectedMedPackSize = 30;
  //   this.res_pricing.length = 0;
  //   this.selectedMedStrength = "";
  //   let form = document.getElementById("myform");
  //   form.addEventListener("onChangeSearch($event)", function (e) {
  //     e.preventDefault();
  //   });
  //   const input = document.querySelector(
  //     ".customautocomplete input"
  //   ) as HTMLInputElement;
  //   input.value = e.target.textContent;
  //   this.selectedDrugObj = this.searchHistory.find(
  //     (f) => f.MedDrugName === e.target.textContent
  //   );
  //   localStorage.setItem("searchHistory", JSON.stringify(this.searchHistory));

  //   if (input.value) {
  //     this.isLoading = true;
  //     this.fetchAndProcessDrugData(input.value, (arr) => {
  //       this.MedicineInValid = false;
  //       this.data = arr;
  //       this.isLoading = false;
  //       this.setInitialSelection();
  //       this.populateUniqueValues();
  //       this.setModalValue();
  //     });

  //     // Add additional API call and form validation logic
  //     this.res_pricing.length = 0;
  //     const formData = this.myForm.value;
  //     let isValid = true;
  //     const zipCodePattern = /^\d{5}(-\d{4})?$/;
  //     const radius = formData.radius;
  //     const zipcode = formData.zipcode;

  //     if (zipcode.length < 5) {
  //       isValid = false;
  //       this.zipcodeInValid = true;
  //       (
  //         document.getElementById("zipInvalidState") as HTMLInputElement
  //       ).textContent = "Enter a valid Zip Code!";
  //     } else {
  //       this.zipcodeInValid = false;
  //     }

  //     if (!zipCodePattern.test(zipcode)) {
  //       isValid = false;
  //       this.zipcodeInValid = true;
  //       event.preventDefault();
  //       (
  //         document.getElementById("zipInvalidState") as HTMLInputElement
  //       ).textContent = "Invalid Zip Code!";
  //     } else {
  //       this.zipcodeInValid = false;
  //     }
  //     if (!isValid) {
  //       event.preventDefault();
  //       return;
  //     }

  //     this.spinner.show(undefined, {
  //       type: "ball-triangle-path",
  //       size: "medium",
  //       bdColor: "rgba(0, 0, 0, 0.8)",
  //       color: "#fff",
  //       fullScreen: true,
  //     });

  //     const obj = {
  //       memberNumber: this.randomMemberID,
  //       personCode: "01",
  //       ndc: this.selectedDrugObj.Ndc,
  //       quantity: this.selectedMedPackSize ? this.selectedMedPackSize : 30,
  //       daysSupply: 30,
  //       groupNum: this.FAQdata.Group.Number,
  //       radius: radius,
  //       zip: zipcode,
  //       maxRecords: 100,
  //     };

  //     this.apiService.getDrugPricing(obj).subscribe(
  //       (response: any) => {
  //         let ApiData = JSON.parse(response).Response;
  //         let ApiFilteredData = ApiData.filter(
  //           (e) => e.Pricing !== null && e.Pricing.Status !== 3
  //         );
  //         if (ApiFilteredData.every((e) => e.Pricing === null)) {
  //           this.error = true;
  //           this.show500Response();
  //           return;
  //         }
  //         if (ApiFilteredData.some((e) => e.Pricing.RejectCodes !== "")) {
  //           ApiFilteredData = ApiFilteredData.filter(
  //             (e) => e.Pricing.RejectCodes === ""
  //           );
  //         }
  //         if (!ApiFilteredData.every((e) => e.Pricing.RejectCodes === "")) {
  //           this.showNullPriceRes();
  //           return;
  //         }

  //         const uniqueAffiliationCodes = {};
  //         const uniqueFilteredData = ApiFilteredData.filter((item) => {
  //           const affiliationCode = item.Pharmacy.AffiliationCode;
  //           if (affiliationCode === "PX1") {
  //             return true;
  //           }
  //           if (!uniqueAffiliationCodes[affiliationCode]) {
  //             uniqueAffiliationCodes[affiliationCode] = true;
  //             return true;
  //           }
  //           return false;
  //         });

  //         const changedPharmacyArr =
  //           this.alterPharmacyNames(uniqueFilteredData);
  //         this.res_pricing = changedPharmacyArr;

  //         let lowestPrice = Number.POSITIVE_INFINITY;
  //         let lowestPriceIndex = null;
  //         for (let i = 0; i < changedPharmacyArr.length; i++) {
  //           const patientPay = changedPharmacyArr[i].Pricing.PatientPay;
  //           if (patientPay < lowestPrice) {
  //             lowestPrice = patientPay;
  //             lowestPriceIndex = i;
  //           }
  //         }
  //         if (lowestPriceIndex !== null) {
  //           changedPharmacyArr[lowestPriceIndex].LowestPrice = true;
  //         }

  //         this.patientPay = changedPharmacyArr[0]["Pricing"].PatientPay;
  //         this.pharmalogo = this.getPharmacyImageURL(
  //           changedPharmacyArr[0]["Pharmacy"].Name
  //         );
  //         this.pharmaName = changedPharmacyArr[0]["Pharmacy"].Name;
  //         if (changedPharmacyArr.length > 0) {
  //           this.res_pricing = changedPharmacyArr;
  //           setTimeout(() => {
  //             document.getElementById("PharamcySection").scrollIntoView({
  //               behavior: "smooth",
  //               block: "nearest",
  //               inline: "nearest",
  //             });
  //             this.spinner.hide();
  //           }, 500);
  //         }
  //         this.populateUniqueValues();
  //         this.setInitialSelection();
  //         this.setModalValue();
  //       },
  //       (error) => {
  //         if (error.status === 500) {
  //           this.error = true;
  //           this.show500Response();
  //         }
  //       }
  //     );
  //   }
  // }

  // onFormSubmit(event: Event) {
  //   this.hideErrorResponse();
  //   let isValid = true;
  //   // this.issLoading = true;
  //   this.res_pricing.length = 0;
  //   const formData = this.myForm.value;
  //   // const drugName = formData.drugName;
  //   const radius = formData.radius;
  //   const zipcode = formData.zipcode;
  //   const zipCodePattern = /^\d{5}(-\d{4})?$/;

  //   if (zipcode.length < 5) {
  //     isValid = false;
  //     this.zipcodeInValid = true;
  //     (
  //       document.getElementById("zipInvalidState") as HTMLInputElement
  //     ).textContent = "Enter a valid Zip Code!";
  //   } else {
  //     this.zipcodeInValid = false;
  //   }

  //   if (this.MedicineArr.length < 1) {
  //     isValid = false;
  //     this.MedicineInValid = true;
  //   } else {
  //     this.MedicineInValid = false;
  //   }
  //   if (this.selectedDrugObj["Ndc"] == undefined) {
  //     isValid = false;
  //     this.MedicineInValid = true;
  //   } else {
  //     this.MedicineInValid = false;
  //   }

  //   if (!zipCodePattern.test(zipcode)) {
  //     isValid = false;
  //     this.zipcodeInValid = true;
  //     event.preventDefault();
  //     (
  //       document.getElementById("zipInvalidState") as HTMLInputElement
  //     ).textContent = "Invalid Zip Code!";
  //   } else {
  //     this.zipcodeInValid = false;
  //   }

  //   if (!isValid) {
  //     event.preventDefault();
  //     return;
  //   }
  //   this.spinner.show(undefined, {
  //     type: "ball-triangle-path",
  //     size: "medium",
  //     bdColor: "rgba(0, 0, 0, 0.8)",
  //     color: "#fff",
  //     fullScreen: true,
  //   });
  //   const obj = {
  //     memberNumber: this.randomMemberID,
  //     personCode: "01",
  //     ndc: this.selectedDrugObj.Ndc,
  //     quantity: this.selectedMedPackSize ? this.selectedMedPackSize : 30,
  //     daysSupply: 30,
  //     groupNum: this.FAQdata.Group.Number, //"NILRXSAMPLE",   // GROUP.Number this.FAQdata.Group.Number
  //     radius: radius,
  //     zip: zipcode,
  //     maxRecords: 100,
  //   };

  //   this.apiService.getDrugPricing(obj).subscribe(
  //     (response: any) => {
  //       // this.issLoading = false;
  //       let ApiData = JSON.parse(response).Response;
  //       let ApiFilteredData = ApiData.filter(
  //         (e) => e.Pricing !== null && e.Pricing.Status !== 3
  //       );
  //       if (ApiFilteredData.every((e) => e.Pricing === null)) {
  //         this.error = true;
  //         this.show500Response();
  //         return;
  //       }
  //       if (ApiFilteredData.some((e) => e.Pricing.RejectCodes !== "")) {
  //         ApiFilteredData = ApiFilteredData.filter(
  //           (e) => e.Pricing.RejectCodes === ""
  //         );
  //       }
  //       if (!ApiFilteredData.every((e) => e.Pricing.RejectCodes === "")) {
  //         //set error
  //         this.showNullPriceRes();
  //         // ApiFilteredData = [];
  //         return;
  //       }

  //       const uniqueAffiliationCodes = {};

  //       // Filter the array to keep only objects with unique AffiliationCode
  //       const uniqueFilteredData = ApiFilteredData.filter((item) => {
  //         const affiliationCode = item.Pharmacy.AffiliationCode;
  //         // Always include items with AffiliationCode "PX1"
  //         if (affiliationCode === "PX1") {
  //           return true;
  //         }
  //         // For other codes, check if they are unique
  //         if (!uniqueAffiliationCodes[affiliationCode]) {
  //           uniqueAffiliationCodes[affiliationCode] = true;
  //           return true;
  //         }
  //         return false;
  //       });
  //       function alterPharmacyNames(array) {
  //         for (let i = 0; i < array.length; i++) {
  //           if (array[i].Pharmacy.AffiliationCode !== "PX1") {
  //             array[i].Pharmacy.Name = array[i].Pharmacy.Name.replace(
  //               /Pharmacy/gi,
  //               ""
  //             )
  //               .replace(/(#\d+|\d+|-)/g, " ")
  //               .trim();
  //           }
  //         }
  //         array.sort((a, b) => a.Pricing.PatientPay - b.Pricing.PatientPay);
  //         return array;
  //       }
  //       const changedPharmacyArr = alterPharmacyNames(uniqueFilteredData);
  //       console.log(changedPharmacyArr);
  //       this.res_pricing = changedPharmacyArr;

  //       // Lowest Price Code
  //       let lowestPrice = Number.POSITIVE_INFINITY;
  //       let lowestPriceIndex = null;
  //       for (let i = 0; i < changedPharmacyArr.length; i++) {
  //         const patientPay = changedPharmacyArr[i].Pricing.PatientPay;
  //         if (patientPay < lowestPrice) {
  //           lowestPrice = patientPay;
  //           lowestPriceIndex = i;
  //         }
  //       }

  //       // Check if a lowest price was found
  //       if (lowestPriceIndex !== null) {
  //         // Add a "LowestPrice" property to the pharmacy object with the lowest price
  //         changedPharmacyArr[lowestPriceIndex].LowestPrice = true;
  //       }

  //       this.patientPay = changedPharmacyArr[0]["Pricing"].PatientPay;
  //       this.pharmalogo = this.getPharmacyImageURL(
  //         changedPharmacyArr[0]["Pharmacy"].Name
  //       );
  //       this.pharmaName = changedPharmacyArr[0]["Pharmacy"].Name;
  //       if (changedPharmacyArr && changedPharmacyArr.length > 0) {
  //         this.res_pricing = changedPharmacyArr;
  //         setTimeout(() => {
  //           document.getElementById("PharamcySection").scrollIntoView({
  //             behavior: "smooth",
  //             block: "nearest",
  //             inline: "nearest",
  //           });
  //           this.spinner.hide();
  //         }, 500);
  //       }
  //       this.populateUniqueValues();
  //       this.setInitialSelection();
  //       this.setModalValue();
  //     },
  //     (error) => {
  //       if (error.status === 500) {
  //         this.error = true;
  //         this.show500Response();
  //       }
  //     }
  //   );
  // }

  selectEvent(item) {
    this.selectedDrugObj = item;
    this.MedicineArr = this.apiResponse.filter(
      (e) => e.MedDrugName === this.selectedDrugObj.MedDrugName
    );
    if (this.searchHistory.length >= 5) {
      this.searchHistory.shift(); // Remove the oldest item
    }
    // Check if an object with the same name already exists
    const existingDrug = this.searchHistory.find(
      (e) => e.MedDrugName === item.MedDrugName
    );
    if (!existingDrug) {
      this.searchHistory.push(item);
    }
    localStorage.setItem("searchHistory", JSON.stringify(this.searchHistory));
    this.populateUniqueValues();
    this.setInitialSelection();
  }

  onFocused(e) {
    // Do something when input is focused
  }

  setIntialLocalStorage(): void {
    const intialValue = localStorage.getItem("intialVal");
    if (intialValue == null) {
      localStorage.clear();
      localStorage.setItem("intialVal", new Date().toUTCString());
    }
  }

  FetchFAQ() {
    this.themeService.getThemeDataObservable().subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.FAQdata = JSON.parse(data).NILRxPharmacyCardInfos[0];
        this.FAQdata.HelpDeskPhone = this.FAQdata.HelpDeskPhone
          ? this.formatPhoneNumber(this.FAQdata.HelpDeskPhone)
          : "";
        this.HeaderBackgroundColor = JSON.parse(data).HeaderBackgroundColor;
        this.FAQdata.GroupNum = this.FAQdata.Group.Number;
      }
    });
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  ngOnInit() {
    // Initialization logic
    this.myForm = this.formBuilder.group({
      drugName: [""],
      radius: ["10"], // Set a default value for radius
      zipcode: [""],
    });
    this.setIntialLocalStorage();
    const storedSearchHistory = localStorage.getItem("searchHistory");
    if (storedSearchHistory) {
      this.searchHistory = JSON.parse(storedSearchHistory);
    }
    this.FetchFAQ();
    this.randomMemberID = this.generateRandomNumber();
    this.themeService.getThemeDataObservable().subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        const settings = localStorage.getItem("settings");
        const parsedData = JSON.parse(settings);
        this.HomeText = parsedData.HomeScreenText;
        this.Disclaimer = parsedData.Disclaimer;
        parsedData.BannerText ? (document.title = parsedData.BannerText) : "";
        const imageBAse64 = localStorage.getItem("LogoPath");
        this.logoUrl = imageBAse64 ? imageBAse64 : null;
      }
    });
  }

  ClearSelectedVal(e) {
    const deleteSearchTerm = e.target.parentElement.firstChild.textContent;
    this.searchHistory.splice(
      this.searchHistory.findIndex((e) => e.MedDrugName === deleteSearchTerm),
      1
    );
    localStorage.setItem("searchHistory", JSON.stringify(this.searchHistory));
  }

  alterPharmacyNames(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].Pharmacy.AffiliationCode !== "PX1") {
        array[i].Pharmacy.Name = array[i].Pharmacy.Name.replace(
          /Pharmacy/gi,
          ""
        )
          .replace(/(#\d+|\d+|-)/g, " ")
          .trim();
      }
    }
    array.sort((a, b) => a.Pricing.PatientPay - b.Pricing.PatientPay);
    return array;
  }
  show500Response() {
    this.error = true;
    this.errorMessage =
      "We are unable to get a price. Please check your Drug and Zip Code and try again.";
    this.spinner.hide();
  }
  showNullPriceRes() {
    this.error = true;
    this.errorMessage =
      "We are unable to price this item. Please try again later.";
    // document.getElementById('ApierrorAlert').querySelector('div > span').textContent = ''
    this.spinner.hide();
  }
  hideErrorResponse() {
    this.error = false;
  }
  getPharmacyImageURL(pharmacyName: string): string | undefined {
    let defaultImage;
    let number = this.res_pricing.findIndex(
      (e) => e.Pharmacy.Name == pharmacyName
    );
    if (number % 2 == 0) {
      defaultImage = this.pharmacyImages.defaultPharamcy2;
    } else {
      defaultImage = this.pharmacyImages.defaultPharamcy1;
    }
    const processedName = this.processPharmacyName(pharmacyName);
    return this.pharmacyImages[processedName]
      ? this.pharmacyImages[processedName]
      : defaultImage;
  }

  processPharmacyName(name: string): string {
    // Remove "Pharmacy" and "#" followed by numbers
    let processedName = name
      .replace(/Pharmacy/gi, "")
      .replace(/(#\d+|\d+|-)/g, "")
      .trim()
      .replace(/\s+/g, "");
    return processedName;
  }

  processPharmacyNameWithSpace(name: string): string {
    // Remove "Pharmacy" and "#" followed by numbers
    let processedName = name
      .replace(/Pharmacy/gi, "")
      .replace(/(#\d+|\d+|-)/g, "")
      .trim();
    return processedName;
  }

  formatUSD(number) {
    if (number === undefined || number === null) {
      number = 0;
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  }

  generateRandomNumber() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomString = "NIL";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }
    return randomString;
  }

  handleButtonClick(index: number, item: any) {
    this.patientPay = item["Pricing"].PatientPay;
    this.pharmalogo = this.getPharmacyImageURL(item["Pharmacy"].Name);
    this.pharmaName = item["Pharmacy"].Name;
    this.selectedButtonIndex = index;
  }

  openPrintWindow() {
    const queryString = `bin=${this.FAQdata.Bin}&pcn=${
      this.FAQdata.Pcn
    }&groupid=${this.FAQdata.GroupNum}&memberid=${
      this.randomMemberID
    }&helpdesk=${this.FAQdata.HelpDeskPhone}&pharma=${this.pharmaName}&price=${
      this.patientPay < 999999 ? this.formatUSD(this.patientPay) : null
    }&name=${
      this.selectedDrugObj.MedDrugName
    }&df=${this.selectedDrugObj.DosageForm.toLowerCase()}&mds=${
      this.selectedMedPackSize
        ? this.selectedMedPackSize
        : this.selectedDrugObj.MedPackSize
    }&uom=${this.selectedDrugObj.Uom?.toLowerCase() || ""}&mdStr=${
      this.selectedDrugObj.MedStrength || ""
    }&qty=${
      this.selectedMedPack
        ? this.selectedMedPack
        : this.selectedDrugObj.MedPackSize
    }&headingbgColor=${
      this.HeaderBackgroundColor
        ? this.HeaderBackgroundColor.substring(1)
        : null
    }`;
    const url = `./pages/print?${queryString}`;
    window.open(url, "_blank");
  }

  openPrintWindows(index: number, item: any) {
    this.patientPay = item["Pricing"].PatientPay;
    this.pharmalogo = this.getPharmacyImageURL(item["Pharmacy"].Name);
    this.pharmaName = item["Pharmacy"].Name;
    this.selectedButtonIndex = index;
    const queryString = `bin=${this.FAQdata.Bin}&pcn=${
      this.FAQdata.Pcn
    }&groupid=${this.FAQdata.GroupNum}&memberid=${
      this.randomMemberID
    }&helpdesk=${this.FAQdata.HelpDeskPhone}&pharma=${
      this.pharmaName
    }&price=${this.formatUSD(this.patientPay)}&name=${
      this.selectedDrugObj.MedDrugName
    }&df=${this.selectedDrugObj.DosageForm.toLowerCase()}&mds=${
      this.selectedMedPackSize
        ? this.selectedMedPackSize
        : this.selectedDrugObj.MedPackSize
    }&uom=${this.selectedDrugObj.Uom?.toLowerCase() || ""}&mdStr=${
      this.selectedDrugObj.MedStrength || ""
    }&qty=${
      this.selectedMedPack
        ? this.selectedMedPack
        : this.selectedDrugObj.MedPackSize
    }&headingbgColor=${
      this.HeaderBackgroundColor
        ? this.HeaderBackgroundColor.substring(1)
        : null
    }`;
    const url = `./pages/print?${queryString}`;
    window.open(url, "_blank");
  }

  handleButtonClicks(index: number, item: any) {
    this.patientPay = item["Pricing"].PatientPay;
    this.pharmalogo = this.getPharmacyImageURL(item["Pharmacy"].Name);
    this.pharmaName = item["Pharmacy"].Name;
    setTimeout(() => {
      document.getElementById("PharamcySection").scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
      this.spinner.hide();
    }, 500);
  }

  ClearAllData() {
    this.res_pricing = [];
    this.uniqueDosageForms = [];
    this.uniqueMedStrengths = [];
    (
      document.querySelector(".customautocomplete input") as HTMLInputElement
    ).value = "";
    this.data = [];
    this.selectedMedPackSize = 30;
    this.selectedMedPack = 30;
    this.selectedDrugObj.MedPackSize = 30;
    this.hideErrorResponse();
    this.res_pricing.length = 0;
    this.selectedDrugObj = {};
    this.myForm = this.formBuilder.group({
      drugName: [""],
      radius: ["10"], // Set a default value for radius
      zipcode: [""],
    });
  }

  preventScroll(event: WheelEvent): void {
    event.preventDefault(); // Prevent the default scroll behavior
  }
}
